export default {
  SEARCH_BOX_PLACEHOLDER: 'Search by Product, SKU, or Brand',

  FACET_TOGGLE_LABEL: `Show Universal Parts 
  <span class="cm_tooltip-btn"> ? 
    <div class="cm_tooltip-content cm_hide">
      Shows search results including universal products if enabled, otherwise shows only products with fitments.
    </div>
  </span>`,

  VERIFY_FITMENT_FITS: 'This product fits your vehicle:',
  VERIFY_FITMENT_NOT_FIT: 'This product does not fit your',

  CHANGE_TEXT: 'Clear selected vehicle',

  GARAGE_TITLE: 'View your garage',
};
