
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function onError1(e) {
                    e.target.classList.add('cm_hide');
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', {}, _createElement('product-card', { 'class': 'card card--product h-full card--product-compare relative flex' }, _createElement('div', { 'className': 'card__media relative' }, _createElement('a', {
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title),
                    'className': 'media block relative image-blend js-prod-link',
                    'tabIndex': '-1'
                }, _createElement('div', {
                    'data-wlh-id': this.id,
                    'data-wlh-link': this.url,
                    'data-wlh-variantid': this.variant_ids[0],
                    'data-wlh-price': this.price,
                    'data-wlh-name': this.removeHTML(this.title),
                    'data-wlh-image': this.image,
                    'className': 'wishlist-hero-custom-button wishlisthero-floating',
                    'data-wlh-mode': 'icon_only',
                    'data-wlh-view': 'Collection'
                }, _createElement('div', {}, _createElement('button', {
                    'aria-label': 'Add to wishlist',
                    'title': 'Add to wishlist',
                    'className': window.location.pathname === '/pages/search' ? `cm_wishlist-button cm_wishlist-id--${ this.id }` : ''
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'data-prefix': 'far',
                    'data-icon': 'heart',
                    'className': 'svg-inline--fa fa-heart fa-w-16 fa-1x',
                    'role': 'img',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'viewBox': '0 0 512 512',
                    'color': '#FC2422'
                }, _createElement('path', {
                    'fill': 'currentColor',
                    'd': 'M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z'
                }))))), _createElement('img', {
                    'className': 'img-fit card__main-image no-js-hidden',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'data-media-id': this.id,
                    'loading': 'lazy',
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), _createElement('div', { 'className': 'product-label-container absolute top-0 start' }, _createElement('div', { 'className': 'product-label-wrapper flex' }, this.out_of_stock ? _createElement('span', {
                    'className': 'product-label product-label--sold-out',
                    'key': '1938'
                }, 'Sold out') : null))), _createElement('div', { 'className': 'card__info-container flex flex-col flex-auto relative' }, _createElement('div', { 'className': 'card__info w-full' }, _createElement('div', { 'className': 'card__info-inner inline-block w-full' }, _createElement('a', {
                    'className': 'card__info-vendor-container',
                    'href': this.vendor_url
                }, _createElement('img', {
                    'src': window.Convermax.config.themeAssetsUrl + this.vendor_handle + '.png',
                    'width': '80',
                    'height': '50',
                    'className': 'vendor-logo',
                    'alt': this.removeHTML(this.vendor),
                    'onError': onError1.bind(this)
                }), _createElement('div', mergeProps({ 'className': 'card__vendor mb-1 text-sm text-theme-light' }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('p', { 'className': 'card__title font-bold mb-1' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'card-link text-current js-prod-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('p', { 'className': 'card__subtitle text-theme-light' }, '\n            ', this.removeHTML(this.custom_vehicle_fitment_subtitle), '\n          '), _createElement('div', {
                    'className': 'pt-5 text-xs product-inventory__status',
                    'data-inventory-level': this.inventory_level
                }, this.inventory_level === 'in_stock' ? ['\n              In stock | 5+ Items Ready to Ship\n            '] : null, this.inventory_level === 'usually_ships_in' ? [
                    '\n              Usually ships in ',
                    this.custom_usually_ships_in,
                    '\n            '
                ] : null, this.inventory_level === 'incoming' ? [
                    '\n              ',
                    this.inventory_quantity,
                    ' Incoming, Arriving soon\n            '
                ] : null, this.inventory_level === 'low' ? [
                    '\n              ',
                    this.inventory_quantity,
                    ' in stock - Hurry while stocks last!\n            '
                ] : null, this.inventory_level === 'very_low' ? [
                    '\n              ',
                    this.inventory_quantity,
                    ' in stock - Almost gone!\n            '
                ] : null, this.inventory_level === 'none' ? ['Out of Stock'] : null), _createElement('div', { 'className': 'price' }, window.Convermax.config.isB2B ? _createElement('div', {
                    'className': 'price__default',
                    'key': '4209'
                }, _createElement('strong', { 'className': 'price__current' }, this.formatPrice(this.price)), window.Convermax.config.isB2B && this.base_price && this.base_price > this.price ? _createElement('span', {
                    'className': 'price__was',
                    'key': '4375'
                }, _createElement('div', { 'className': 'msrp-wrapper' }, _createElement('span', { 'className': 'b2b_price_api' }, _createElement('span', { 'className': 'b2b-rrp' }, 'RRP ', _createElement('span', { 'className': 'b2b-rrp-compare' }, this.formatPrice(this.base_price))), _createElement('span', { 'className': 'percentage-off' }, '(Save ', this.calcDiscount(this.price, this.base_price), '%)')))) : null) : null, !window.Convermax.config.isB2B ? _createElement('div', {
                    'className': 'price__default',
                    'key': '5034'
                }, !this.b2c_percentage_off ? [
                    _createElement('strong', {
                        'className': 'price__current',
                        'key': '51161'
                    }, this.formatPrice(this.price)),
                    this.on_sale ? _createElement('span', {
                        'className': 'price__was',
                        'key': '51163'
                    }, _createElement('div', { 'className': 'msrp-wrapper-b2c' }, _createElement('span', { 'className': 'b2b_price_api' }, _createElement('span', { 'className': 'b2b-rrp' }, _createElement('span', { 'className': 'b2b-rrp-compare' }, this.formatPrice(this.compare_at_price))), _createElement('span', { 'className': 'percentage-off' }, '(Save ', this.calcDiscount(this.price, this.compare_at_price), '%)')))) : null
                ] : null, this.b2c_percentage_off ? [
                    _createElement('strong', {
                        'className': 'price__current',
                        'key': '57801'
                    }, this.formatPrice(window.Convermax.getB2CPrice(this))),
                    this.price > window.Convermax.getB2CPrice(this) ? _createElement('span', {
                        'className': 'price__was',
                        'key': '57803'
                    }, _createElement('div', { 'className': 'msrp-wrapper-b2c' }, _createElement('span', { 'className': 'b2b_price_api' }, _createElement('span', { 'className': 'b2b-rrp' }, 'RRP ', _createElement('span', { 'className': 'b2b-rrp-compare' }, this.formatPrice(this.price))), _createElement('span', { 'className': 'percentage-off' }, '(Save ', Math.floor(((Math.round(this.price * 100) - Math.round(window.Convermax.getB2CPrice(this) * 100)) / this.price).toFixed(0)), '%)')))) : null
                ] : null) : null), this.sku ? _createElement('div', {
                    'className': 'sku text-theme-light',
                    'key': '6695'
                }, 'SKU: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })) : null)), _createElement('div', { 'className': 'card__quick-add mob:card__quick-add--below desktop:card__quick-add--below' }, _createElement('product-form', {}, _createElement('div', {
                    'className': 'alert mb-8 bg-error-bg text-error-text js-form-error text-start',
                    'role': 'alert',
                    'hidden': true
                }), _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'quick-add-' + this.id,
                    'acceptCharset': 'UTF-8',
                    'className': 'js-product-form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), this.out_of_stock || !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('button', {
                    'className': 'btn btn--primary w-full',
                    'data-add-to-cart-text': 'Add to cart',
                    'name': 'add',
                    'aria-haspopup': 'dialog',
                    'disabled': this.out_of_stock,
                    'key': '7545'
                }, _createElement('span', { 'className': 'quick-add-btn-text' }, !this.out_of_stock ? 'Add to cart' : 'Sold out')) : null)), !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('button', {
                    'type': 'button',
                    'className': 'btn btn--primary w-full js-quick-add',
                    'aria-haspopup': 'dialog',
                    'data-product-url': this.url,
                    'data-product-default-variant': 'false',
                    'data-quick-add-listener-added': 'true',
                    'key': '7974'
                }, _createElement('span', { 'className': 'quick-add-btn-text block pointer-events-none' }, 'Choose options')) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'h4 mt-10 cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'ul',
        { 'className': 'slider slider__grid grid grid-flow-col auto-cols-2 small-cards-mobile md:auto-cols-3 lg:auto-cols-4 xl:auto-cols-5 small-cards-desktop gap-x-theme gap-y-8 cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []