
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-body' }, [this.vehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n        ', entry.value, '\n      ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                            'className': 'index' + (this.disabled ? ' disabled' : ''),
                            'key': '117'
                        }, '\n      ', this.index + 1, '\n    ') : null, _createElement.apply(this, [
                            'select',
                            {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            },
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n        ', this.title, '\n      ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            _map(this.entries, repeatEntry1.bind(this))
                        ]), _createElement('svg', {
                            'width': '15',
                            'height': '10',
                            'viewBox': '0 0 15 10',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg'
                        }, _createElement('path', {
                            'd': 'M13 2L7.375 8L1.75 2',
                            'stroke': '#0A0E14',
                            'strokeWidth': '2.5',
                            'strokeLinecap': 'round',
                            'strokeLinejoin': 'round'
                        }))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__dialog cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear btn btn--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle
            }, '\n    Clear\n  ')))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:vehicleWidget"]